.people {
    width: fit-content;
    padding: 0 15px;
    color: white;
    font-family: 'reg';
    border: solid 1px white;
    border-radius: 60px;
    height: 30px;
    position: fixed;
    top: 20px;
    right: 20px;
    line-height: 30px;
    text-align: center;
    z-index: 300;
    pointer-events: none;
}
