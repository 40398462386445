.verzweigen__slogan {
    margin: 0;
    color: white;
    z-index: 100000;
    pointer-events: none;
}

.verzweigen__slogan
.slogan__circle { position: fixed; width: 100%; padding-bottom: 100%; overflow: hidden; }

.slogan__circle text { 
    font-family: 'pixel'; 
    font-size: 10px;
    color: white;
}

.verzweigen__circle {
    top: -90vh;
    left: -40vw;
    position: fixed;
    width:1000px;
    height: 1000px;
    pointer-events: none;
}

.verzweigen__circle text {
    font-family: sans-serif; 
    font-size: 20px;
    color: white;
}
.verzweigen__circle svg {
    height: 200vh;
}

.verzweigen__circle svg,
.slogan__circle svg {
    position: absolute; 
    left: 0; 
    top: 0;
  -webkit-animation-name: rotate;
     -moz-animation-name: rotate;
      -ms-animation-name: rotate;
       -o-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 8s;
     -moz-animation-duration: 8s;
      -ms-animation-duration: 8s;
       -o-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
      -ms-animation-timing-function: linear;
       -o-animation-timing-function: linear;
          animation-timing-function: linear;
          width: 100%; 

}

.verzweigen__slogan--1 .slogan__circle svg { 
    height: 75vh;
}
.verzweigen__slogan--1 .slogan__circle text { 
    font-size: 10px;
}

.verzweigen__slogan--2 .slogan__circle svg { 
    height: 50vh;
}
.verzweigen__slogan--2 .slogan__circle text { 
    font-size: 19px;
}

.verzweigen__slogan--3 .slogan__circle svg { 
    height: 90vh;
}
.verzweigen__slogan--3 .slogan__circle text { 
    font-size: 19px;
}

.verzweigen__slogan--4 {
    top: 1vh;
    left: -40vw;
}
.verzweigen__slogan--4 .slogan__circle svg { 
    height: 40vh;
}
.verzweigen__slogan--4 .slogan__circle text { 
    font-size: 25px;
}

.verzweigen__slogan:hover > .slogan__circle text {
    color: red;
}

// #line1 {
//     display: none;
// }


@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(360deg); }
    to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(360deg); }
    to { -o-transform: rotate(0); }
}
@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

@keyframes rotate-reverse {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

.verzweigen__slogan--1 {
    top: -12vh;
    left: 12vh;
}

.verzweigen__slogan--2 {
    top: 30vh;
    left: 5vw;
}

.verzweigen__slogan--3 {
    top: 25vh;
    left: -25vw;
}

.verzweigen__slogan--3 .slogan__circle text {
    fill: 'red' !important;
}