/* IMAGE */
.image__container {
    position: relative;
    width: 100%;
}
.image__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.image__wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* Mode: Cover */
.image__container.mode--cover {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.lazyloaded {
    width: 100%;
    height: 100%;
    object-fit: contain;
}



