.closed {
    display: block;
    width: 100%;
    height: 100%;
    background-color: black;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000000;
}

.closed__title {
    font-family: 'title';
    font-size: 60px;
    color: transparent;
    -webkit-text-stroke: 1px white;
    color: transparent;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40vh;
    font-weight: normal;
}


.closed__text {
    position: absolute;
    top: calc(40vh + 100px);
    width: 100%;
    text-align: center;
    font-family: 'reg';
    font-size: 20px;
    line-height: 130%;
    opacity: 0.8;
}

.closed span {
    display: block;
    font-size: 60px;
    font-family: 'pixel';
    width: 100%;
    text-align: center;
    position: absolute;
    top: calc(50vh + 110px);
}

.closed__plants {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    opacity: 0.5;
}

.closed__plants img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

@media (max-width:800px) {
    .closed__title {
        font-size: 10vw;
        top: 20vh;
    }
    .closed__text {
        font-size: 2.5vw;
        top: calc(20vw + 20vh);
        margin-top: 70px;
        width: 50vw;
        margin-left: 25vw;
    }
    .closed span {
        font-size: 10vw;
        top: calc(50vh + 100px);
    }
}
