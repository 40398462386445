.video-wrapper {
  width: 146vh;
  height: 100%;
}

.video-container {
  position: relative;
  /* TEMPORARY FIX */
  height: 100%;
  /* padding-bottom: 56.25%;
  height: 0px; */
  overflow: hidden;
}

.keimen__vege1 .video-wrapper, .keimen__vege2 .video-wrapper {
  width: 113vh !important;
}

.verzweigen__video1 .video-wrapper, 
.verzweigen__video2 .video-wrapper {
  width: 132vh !important;
}
.blumen__video1 .video-wrapper, .blumen__video2 .video-wrapper {
  width: 155vh !important;
}

.welken__video1 .video-wrapper {
  height: 72% !important;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .intro__introVideo .video-container {
  padding-bottom: 100% !important;
} */

::cue {
  font-size: 16px;
}

video::-webkit-media-text-track-display {
  font-size: 16px;
}
