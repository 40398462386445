.audio-container, #text-track {
  height: 50px;
  top: 0;
  left: 0;
  width: 100vw;
  position: fixed;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  font-size: 25px;
}

#text-track {
  display: none;
}

